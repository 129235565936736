'use client'
import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from "@/app/not-found";

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        // Update state to show fallback UI
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log the error to an error reporting service
        console.error("ErrorBoundary caught an error", error, errorInfo);
        console.error(error)
    }

    render() {
        if (this.state.hasError) {
            // Customize this UI for your app's fallback
            return <ErrorPage/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
