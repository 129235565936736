"use client";
import "jsvectormap/dist/css/jsvectormap.css";
import "flatpickr/dist/flatpickr.min.css";
import "nouislider/dist/nouislider.css";
import "dropzone/dist/dropzone.css";
import "@/css/satoshi.css";
import "@/css/simple-datatables.css";
import "@/css/style.css";
import React, {useEffect, useState} from "react";
import Loader from "@/components/common/Loader";
import ErrorBoundary from "@/components/Erroroundary";
import ComingSoon from "@/app/auth/coming-soon/page";
import UnderMaintenance from "@/app/auth/under-maintenance/page";

export default function RootLayout({
                                       children,
                                   }: Readonly<{
    children: React.ReactNode;
}>) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const enabled= true
    // const pathname = usePathname();

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <html lang="en">
        <body suppressHydrationWarning={true}>
        {enabled ? <ErrorBoundary>
            <div className="dark:bg-boxdark-2 dark:text-bodydark">
                {loading ? <Loader/> : children}
            </div>
        </ErrorBoundary> : <><UnderMaintenance/></>}
        </body>
        </html>
    );
}
